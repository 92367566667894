import { useInvoiceFindOne } from '@commons';
import { Flex, Spinner, Button } from 'components/ui';
import {
  EditTimeSpentForm,
  FormValues,
} from 'forms/invoices/Provider/EditTimeSpentForm';
import { useParams, useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import { useTranslationFormat } from 'hooks/useTranslateFormat';
import * as STORES from 'store';

type hookStoreProps = any;
export const EditTimeSpent = (props: any) => {
  const location = useLocation();

  const getHookStoreByParams = () => {
    //@ts-ignore
    const hookStore = location.state?.hookStore;
    switch (hookStore) {
      case 'useInvoiceProviderListStore':
        return STORES.useInvoiceProviderListStore;
      default:
        console.warn(
          `Store ${hookStore} not added in function getHookStoreByParams`
        );
        return undefined;
    }
  };
  const hookStore = getHookStoreByParams();
  const { invoiceToBeValidated }: hookStoreProps = hookStore ? hookStore() : {};

  const t = useTranslationFormat();
  const { uuid } = useParams<{ uuid: string }>();
  const [currentInvoiceUuid, setCurrentInvoiceUuid] = useState(uuid);
  const { data: invoice, status } = useInvoiceFindOne(currentInvoiceUuid);
  const loading = status === 'pending';
  // const selectedInvoice = invoiceToBeValidated
  //   ? invoiceToBeValidated?.find(
  //       (i: FormValues) => i?.uuid === currentInvoiceUuid
  //     )
  //   : invoice;
  const haveNext =
    invoiceToBeValidated &&
    invoiceToBeValidated?.findIndex(
      (i: any) => i?.uuid === currentInvoiceUuid
    ) +
      1 <=
      invoiceToBeValidated?.length - 1;
  const havePrevious =
    invoiceToBeValidated &&
    invoiceToBeValidated?.findIndex(
      (i: any) => i?.uuid === currentInvoiceUuid
    ) -
      1 >=
      0;
  const handleChangeInvoice = (nextIndex: number) => {
    const currentIndex = invoiceToBeValidated?.findIndex(
      (i: any) => i?.uuid === currentInvoiceUuid
    );
    if (currentIndex !== -1) {
      const newInvoice = invoiceToBeValidated?.[currentIndex + nextIndex];
      if (newInvoice) {
        setCurrentInvoiceUuid(newInvoice?.uuid);
      }
    }
  };

  if (loading) {
    return (
      <Flex
        top={0}
        left={0}
        bottom={0}
        right={0}
        alignItems="center"
        justifyContent="center"
        position="fixed"
      >
        <Spinner />
      </Flex>
    );
  }
  return (
    <EditTimeSpentForm
      title={`Facture fournisseur ${t(
        `texts:INVOICETYPE_${invoice?.invoiceType}`
      )}`}
      invoice={invoice as FormValues}
      switchButtons={
        invoiceToBeValidated && (
          <Flex mr={5}>
            <Button
              isDisabled={!havePrevious}
              type="button"
              onClick={() => handleChangeInvoice(-1)}
              title="Passer à la facture précédente"
            >
              {'<'}
            </Button>
            <Button
              isDisabled={!haveNext}
              type="button"
              onClick={() => handleChangeInvoice(1)}
              title="Passer à la facture suivante"
              ml={'5px'}
            >
              {'>'}
            </Button>
          </Flex>
        )
      }
      onChangeInvoice={(index: number) => handleChangeInvoice(1)}
      from="MISSION"
    />
  );
};
