import {
  IAdditionalService,
  EAdditionalServiceType,
  EPriceUnit,
  IJoinedAdditionalActivity,
  IFastCashService,
  EAdditionalServiceStatus,
  IInvoiceLine,
  EInvoiceStatus,
  EInvoiceLineType,
} from '@freelancelabs/teoreme-commons';
import { Flex, Text, Box, Row, FormControl, Input, Link } from 'components/ui';
import {
  getServiceValues,
  isCustomerInvoice,
  getInvoiceTotalAmount,
  isExpenseInvoice,
  isStandByDutyInvoice,
  queryClient,
  isCreditNoteInvoice,
} from '@commons';
import { EuroIcon, PercentIcon } from 'components/ui/icons';
import { showEditFastCashModal } from 'components/modals/invoices/EditFastCashModal';
type InvoiceLinesFormProps = {
  invoice?: any;
  activity?: IJoinedAdditionalActivity;
};

export const InvoiceLinesForm = ({
  invoice,
  activity,
}: InvoiceLinesFormProps) => {
  const FCAvalaible =
    (
      invoice?.mission?.additionalServices?.find(
        (as: any) =>
          as?.type === EAdditionalServiceType.FAST_CASH &&
          as?.status === EAdditionalServiceStatus.ACTIVE
      ) as IFastCashService
    )?.catalog?.length > 0;
  const FCActive = invoice?.invoiceLines?.find(
    (il: IInvoiceLine) => il?.type === EAdditionalServiceType.FAST_CASH
  );
  const editableStatus = [
    EInvoiceStatus?.REJECTED,
    EInvoiceStatus?.TO_BE_FILLED,
    EInvoiceStatus?.TO_BE_SUBMITTED,
  ];
  const isEditable = editableStatus?.includes(invoice?.status);
  return (
    <Box>
      {!invoice ||
        !Array.isArray(invoice?.invoiceLines) ||
        (invoice?.invoiceLines.length === 0 &&
          !FCAvalaible &&
          'Aucun service complémentaire')}
      {isEditable &&
        FCAvalaible &&
        !FCActive &&
        !isCreditNoteInvoice(invoice?.invoiceType) &&
        !isExpenseInvoice(invoice?.invoiceType) &&
        !isStandByDutyInvoice(invoice?.invoiceType) && (
          <Link
            onClick={() =>
              showEditFastCashModal({
                invoice: invoice,
              }).then(action =>
                queryClient.refetchQueries({ queryKey: [invoice?.uuid] })
              )
            }
            ml={'5px'}
          >
            <Flex>Activer le fast Cash</Flex>
          </Link>
        )}
      {invoice &&
        Array.isArray(invoice?.invoiceLines) &&
        invoice.invoiceLines
          .sort((a: IAdditionalService, b: IAdditionalService) =>
            a.type === EAdditionalServiceType.FAST_CASH
              ? -1
              : a.type === EAdditionalServiceType.HANDLING_FEE ||
                  a.type === EAdditionalServiceType.MISC
                ? 1
                : -1
          )
          .map((line: any) => {
            // const contractorRate = invoice.contractorRate || 0;
            const nbWorkingDays = invoice.nbWorkingDays || 0;
            // const clientRate = invoice.clientRate || 0;
            // const totalAmountHT = isStandByDutyInvoice(
            //   invoice?.invoiceType as EInvoiceType
            // )
            //   ? getAdditionalActivitiesTotalAmount(
            //       [activity as IJoinedAdditionalActivity],
            //       EadditionalActivityType?.STAND_BY_DUTY,
            //       isCustomerInvoice(invoice?.invoiceType as EInvoiceType)
            //         ? 'CUSTOMER'
            //         : 'PROVIDER'
            //     )
            //   : isExpenseInvoice(invoice?.invoiceType as EInvoiceType)
            //   ? getAdditionalActivitiesTotalAmount(
            //       [activity as IJoinedAdditionalActivity],
            //       EadditionalActivityType?.EXPENSE,
            //       isCustomerInvoice(invoice?.invoiceType as EInvoiceType)
            //         ? 'CUSTOMER'
            //         : 'PROVIDER'
            //     )
            //   : nbWorkingDays * contractorRate;
            const totalAmountHT = getInvoiceTotalAmount(
              invoice,
              isCustomerInvoice(invoice?.invoiceType) ? 'CUSTOMER' : 'PROVIDER',
              activity
            );
            const missionService =
              invoice?.mission?.additionalServices?.[
                invoice?.mission?.additionalServices?.findIndex(
                  (ms: IAdditionalService) => ms.uuid === line.uuid
                )
              ];
            const values = getServiceValues(
              totalAmountHT,
              nbWorkingDays,
              line,
              invoice
            );
            return (
              <Flex justifyContent="space-between">
                <Box width={5 / 10} mt={35}>
                  <Text variant="span" mr={'5px'}>
                    {/*  TODO CHANGE VALUE AFTER MAKE ADDITIONAL SERVICE FEATURE*/}
                    <b>
                      {values.labelType?.replaceAll(
                        '{deadline}',
                        line?.metadata?.deadline
                      )}
                    </b>
                    {/*line?.type === EAdditionalServiceType.FAST_CASH && (
                    <Link ml={'5px'}>Modifier</Link>
                  )*/}
                  </Text>
                  {isEditable &&
                    FCAvalaible &&
                    !isCreditNoteInvoice(invoice?.invoiceType) &&
                    !isExpenseInvoice(invoice?.invoiceType) &&
                    !isStandByDutyInvoice(invoice?.invoiceType) &&
                    missionService?.paidByProvider && (
                      <Link
                        onClick={() =>
                          showEditFastCashModal({
                            invoice: invoice,
                            invoiceLine: line,
                          }).then(action =>
                            queryClient.refetchQueries({
                              queryKey: [invoice?.uuid],
                            })
                          )
                        }
                        ml={'5px'}
                      >
                        <Flex>Modifier</Flex>
                      </Link>
                    )}
                </Box>
                <Row spacing={0} width={5 / 10}>
                  {line?.type === EInvoiceLineType.FREE_DAYS && (
                    <FormControl label="Nombre de jours">
                      <Input
                        isDisabled
                        isFullWidth
                        pr={20}
                        value={line.amount}
                        icon={
                          line.unit === EPriceUnit.PERCENT_INVOICE ? (
                            <PercentIcon style={{ marginRight: 5 }} />
                          ) : (
                            <EuroIcon style={{ marginRight: 5 }} />
                          )
                        }
                      />
                    </FormControl>
                  )}
                  {line?.type !== EAdditionalServiceType.MISC && (
                    <FormControl label={values.labelUnit}>
                      <Input
                        isDisabled
                        isFullWidth
                        pr={20}
                        value={line.price}
                        icon={
                          line.unit === EPriceUnit.PERCENT_INVOICE ? (
                            <PercentIcon style={{ marginRight: 5 }} />
                          ) : (
                            <EuroIcon style={{ marginRight: 5 }} />
                          )
                        }
                      />
                    </FormControl>
                  )}
                  <FormControl label="Montant total HT">
                    <Input
                      isDisabled
                      isFullWidth
                      value={`${values.price}`}
                      icon={<EuroIcon style={{ marginRight: 5 }} />}
                    />
                  </FormControl>
                </Row>
              </Flex>
            );
          })}
    </Box>
  );
};
