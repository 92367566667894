import {
  extractVerifyRib,
  invoiceFindMany,
  managerExtractVerifyRibNeedWarning,
  providerExtractVerifyRibNeedWarning,
} from '@commons';
import { CheckIcon, CloseIcon, EyeIcon } from 'components/ui/icons';
import { ModalFrame, ModalProps } from 'components/modals/ModalFrame';
import {
  Box,
  Button,
  Flex,
  Link,
  Text,
  Status,
  Spinner,
  ALink,
} from 'components/ui';
import React, { ReactNode, useEffect, useState } from 'react';

import { create } from 'react-modal-promise';
import { Theme } from 'styles';

type Props = ModalProps & {
  beforeValidation?: () => void;
  layer?: number;
  title: string;
  text: string | ReactNode;
  confirmLabelButton?: any;
  cancelLabelButton?: any;
  checkInvoice?: boolean;
  data?: any;
};
enum ELoadingStatus {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export const OnConfirmFormModal = ({
  onResolve,
  beforeValidation,
  isOpen,
  title,
  text,
  confirmLabelButton,
  cancelLabelButton,
  layer,
  checkInvoice,
  data,
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = React.useState(false);
  const [isLoadingCheckIban, setIsLoadingCheckIban] =
    React.useState<ELoadingStatus>(ELoadingStatus?.IDLE);
  const [warningIBAN, setWarningIBAN] = useState(false);
  const [isLoadingCheckInvoiceNumber, setIsLoadingCheckInvoiceNumber] =
    React.useState<ELoadingStatus>(ELoadingStatus?.IDLE);
  const [warningInvoiceReference, setWarningInvoiceReference] = useState(false);
  const [isLoadingCheckDuplicateInvoice, setIsLoadingCheckDuplicateInvoice] =
    React.useState<ELoadingStatus>(ELoadingStatus?.IDLE);
  const [warningDuplicate, setWarningDuplicate] = useState(false);
  const [duplicateInvoice, setDuplicateInvoice] = useState<any>([]);
  const [checkIsFinish, setCheckIsFinish] = useState(
    checkInvoice ? false : true
  );

  const onValidate = async () => {
    try {
      if (beforeValidation) {
        setLoading(true);
        await beforeValidation();
        setLoading(false);
      }
      onResolve(true);
    } catch (err) {
      console.log(err);
    }
  };
  const handleCheckInvoice = async () => {
    try {
      //verification IBAN
      setIsLoadingCheckIban(ELoadingStatus.PENDING);
      const verifyIBAN = await extractVerifyRib({
        fileLocation: data?.invoiceFile?.fileLocation as string,
        invoice: data?.invoice?.uuid,
        //@ts-ignore
        customSearch: [
          {
            name: 'providerRef',
            regex: data?.providerInvoiceRef,
            options: 'i',
          },
        ],
      });

      if (managerExtractVerifyRibNeedWarning(verifyIBAN)) {
        setWarningIBAN(true);
        setIsLoadingCheckIban(ELoadingStatus.ERROR);
      } else {
        setIsLoadingCheckIban(ELoadingStatus.SUCCESS);
      }

      setIsLoadingCheckInvoiceNumber(ELoadingStatus.PENDING);
      //@ts-ignore
      let resultSearch = verifyIBAN?.customSearch?.find(
        (cs: any) => cs?.providerRef === true
      );
      if (!resultSearch) {
        setIsLoadingCheckInvoiceNumber(ELoadingStatus.ERROR);
        setWarningInvoiceReference(true);
      } else {
        setIsLoadingCheckInvoiceNumber(ELoadingStatus.SUCCESS);
      }

      //verification N° facture doublon
      setIsLoadingCheckDuplicateInvoice(ELoadingStatus.PENDING);
      const verifyDuplicate = await invoiceFindMany({
        filterObject: {
          providerInvoiceRef: data?.providerInvoiceRef,
          uuid: { $nin: [data?.invoice?.uuid] },
          estProvider: data?.invoice?.estProvider?.uuid,
        },
      });

      if (verifyDuplicate?.totalCount > 0) {
        setIsLoadingCheckDuplicateInvoice(ELoadingStatus.ERROR);
        setDuplicateInvoice(verifyDuplicate?.invoices);
        setWarningDuplicate(true);
      } else {
        setIsLoadingCheckDuplicateInvoice(ELoadingStatus.SUCCESS);
      }
    } catch (e) {
      //
    }
    setCheckIsFinish(true);
  };
  useEffect(() => {
    if (checkInvoice) {
      handleCheckInvoice();
    }
  }, []);
  const statusIcon = {
    IDLE: <></>,
    PENDING: (
      <Box ml={10}>
        <Spinner size={15} color={Theme?.colors?.warning?.default} />
      </Box>
    ),
    SUCCESS: <CheckIcon fontSize={15} fill={Theme?.colors?.success?.default} />,
    ERROR: <CloseIcon fontSize={15} fill={Theme?.colors?.error?.default} />,
  };
  const variantColor = {
    IDLE: 'grey',
    PENDING: 'warning',
    SUCCESS: 'success',
    ERROR: 'error',
  };
  console.log('warningIBAN', warningIBAN);
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={570}
      closeIcon
      layer={layer}
    >
      <>
        <Box hidden={!checkInvoice}>
          <Text variant="h1">
            {!checkIsFinish ? 'Vérification des informations' : title}
          </Text>
          <Text variant="p" mt={20}>
            {text}
          </Text>
          <Box mt={20}>
            <Box p={10}>
              <Status
                //@ts-ignore
                variantColor={variantColor[isLoadingCheckIban]}
              >
                Vérification de l'IBAN
                {statusIcon[isLoadingCheckIban]}
              </Status>
              {warningIBAN && (
                <Box mt={20}>
                  <Text variant="p">
                    L’IBAN spécifié sur cette facture n’est pas celui qui est
                    configuré par défaut pour cette société dans la Gescom.
                  </Text>
                </Box>
              )}
            </Box>
            <Box p={10}>
              <Status
                //@ts-ignore
                variantColor={variantColor[isLoadingCheckInvoiceNumber]}
              >
                Vérification du numéro de facture
                {statusIcon[isLoadingCheckInvoiceNumber]}
              </Status>
              {warningInvoiceReference && (
                <Box mt={20}>
                  <Text variant="p">
                    Le numéro de facture saisi ne semble pas être présent sur la
                    facture importée.
                  </Text>
                </Box>
              )}
            </Box>
            <Box p={10}>
              <Status
                //@ts-ignore
                variantColor={variantColor[isLoadingCheckDuplicateInvoice]}
              >
                Vérification de la duplication
                {statusIcon[isLoadingCheckDuplicateInvoice]}
              </Status>
              {warningDuplicate && (
                <Box mt={20}>
                  <Text variant="p">
                    Ce numéro de facture a déjà été utilisé, asurez vous
                    d’utiliser un numéro de facture unique pour chaque facture
                    déposée.
                  </Text>
                  <ALink
                    target="_blank"
                    href={`/orders/invoices/provider/time-spent/${duplicateInvoice?.[0]?.uuid}`}
                  >
                    <Link iconLeft={<EyeIcon />}> Voir la facture</Link>
                  </ALink>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box hidden={!checkIsFinish}>
          <Box hidden={checkInvoice}>
            <Text variant="h3">{title}</Text>
            <Text variant="p" mt={20}>
              {text}
            </Text>
          </Box>

          <Flex
            mt={20}
            justifyContent="space-around"
            alignContent="space-between"
            flex="wrap"
          >
            {confirmLabelButton && (
              <Button
                onClick={onValidate}
                isFullWidth={!cancelLabelButton}
                variant="filled"
              >
                {confirmLabelButton}
              </Button>
            )}
            {cancelLabelButton && (
              <Button
                onClick={() => onResolve(false)}
                isFullWidth={!confirmLabelButton}
                variant="ghost"
              >
                {cancelLabelButton}
              </Button>
            )}
          </Flex>
        </Box>
      </>
    </ModalFrame>
  );
};

export const showOnConfirmFormModal = create<Props>(OnConfirmFormModal);
