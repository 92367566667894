import {
  getFullName,
  getTradeNameSafe,
  removeOffsetDate,
  useCreateAditionalActivity,
  useAdditionalActivityFindOne,
  useAdditionalActivityUpdateOne,
  useMissionFindOne,
  queryClient,
  formatDate,
  FR_STATUS,
  ADDIONAL_FILES_NUMBER,
  getFormulaDutyFormulaAmount,
  getHumanDateMonthAndYear,
  round,
  transformText,
  getAdditionActivityTextType,
} from '@commons';
import { useForm } from 'react-hook-form';
import {
  EadditionalActivityStatus,
  IJoinedMission,
  IStandByDutyLine,
  EadditionalActivityType,
  IAdditionalActivityStandByDutyData,
  IJoinedAdditionalActivity,
  EChangeOwnerType,
} from '@freelancelabs/teoreme-commons';
import { Header } from 'components/Header';
import { showRejectAdditionalActivityModal } from 'components/modals/RejectAdditionalActivityModal';
import TextInformation from 'components/TextInformation';
import { showDialogModal } from 'components/modals/DialogModal';

import {
  Tab,
  Box,
  Button,
  Flex,
  LabelField,
  Link,
  SpinnerBox,
  Text,
  TextArea,
  FormControl,
  ColorTextBox,
  MenuItem,
  Status,
  FileListControlled,
} from 'components/ui';
import { AddIcon, EuroIcon, EditIcon, DeleteIcon } from 'components/ui/icons';
import { useShowMessage } from 'hooks/useShowMessage';
//@ts-ignore
import jsonQ from 'jsonq';
import React, { useState, useEffect } from 'react';
import { Theme } from 'styles';
import { AddDutyLine } from './AddDutyLine';
import { showValidateAdditionalActivityModal } from 'components/modals/activities/ValidateAdditionalActivityModal';
import { FileCard } from 'components/cards';
import { showImportBDCAdditionalActivityModal } from 'components/modals/activities/ImportBDCModal';
import AddBloc from 'components/AddBloc';
import { HistoryList } from 'components/lists/HistoryList';
import { columns } from './HistoryListData';
import { ActivityQuoteCard } from 'routes/Orders/ActivityQuoteCard';
type CreateStandByDutyProps = {
  month: Date;
  missionRef: string;
  method: 'CREATE' | 'UPDATE';
  uuid?: string;
  onResolve?: (params: any) => void;
};
type Line = IStandByDutyLine & {
  lock?: boolean;
  key?: string;
};
const defaultLine = {
  lock: false,
};
export const CreateStandByDutyForm: React.FC<
  React.PropsWithChildren<CreateStandByDutyProps>
> = ({ month, missionRef, method, uuid, onResolve }) => {
  const [formSelected, setFormSelected] = useState<'INFORMATIONS' | 'HISTORY'>(
    'INFORMATIONS'
  );
  const { data: activity, isFetching: isFetchingAddtional } =
    useAdditionalActivityFindOne(uuid);
  const { data: mission, isFetching: isFetchingMission } =
    useMissionFindOne(missionRef);
  const [providerComment, setProviderComment] = useState(
    activity?.providerComment
  );
  const showMessage = useShowMessage();
  const [isLoading, setIsLoading] = useState(false);
  const standByDutyLines: Line[] = activity?.standByDutyLines || [];
  const [lines, setLines] = useState<Line[] | []>(
    method === 'UPDATE' ? standByDutyLines : []
  );
  const [loading, setLoading] = useState(false);
  const [onCreate, setOnCreate] = useState(method === 'CREATE' ? true : false);
  const [onUpdateLine, setOnUpdateLine] = useState<string | false>(false);
  const missionStandByDutyFormulas = mission?.standByDutyFormulas || [];

  const {
    control,
    getValues,
    setError,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      //@ts-ignore
      attachments: activity?.attachments,
    },
  });
  const existingAttachments = activity?.attachments;

  //const onEdit = lines?.findIndex((l: Line) => !l?.lock) !== -1;
  // TODO FILTRE BY DATE AND ACTIVE !!!
  const avalaibleFormulas = [...missionStandByDutyFormulas]?.map((f: any) => {
    return {
      key: f?.uuid,
      label: `${f?.name} - ${f?.description} `,
      value: f?.uuid,
      data: f,
    };
  });
  const isAttachmentsRequired = (availablesFormulas: any, lines: any) => {
    const linesUuids = lines?.map((l: any) => {
      return l.standByDutyFormulaLineUuid;
    });

    const formulasUsed = availablesFormulas.filter((f: any) =>
      linesUuids.includes(f.key)
    );
    for (const elt of formulasUsed) {
      if (elt.data.shouldJoinAttachment) return true;
    }
    return false;
  };
  const handleCreateOrUpdateLine = (line: Line) => {
    const updatedLines = jsonQ.clone(lines);
    const index = lines?.findIndex(
      (l: Line) =>
        (line?.uuid && l?.uuid === line?.uuid) || l?.key === line?.key
    );
    if (index !== -1) {
      updatedLines[index] = line;
    } else {
      updatedLines?.push(line);
    }

    setOnUpdateLine(false);
    setLines(updatedLines);
    setOnCreate(false);
  };
  const onDeletePo = async () => {
    const confirm = await showDialogModal({
      title: 'Êtes-vous sûr(e) de vouloir supprimer le BDC client?',
      text: 'Attention ce document sera définitivement supprimé de Connecteed.',
      confirmLabel: 'Confirmer',
      cancelLabel: 'Annuler',
    });

    if (!confirm) return;

    setLoading(true);
    await updateAdditionalActivity({
      uuid: uuid,
      purchaseOrder: {
        // @ts-ignore
        file: null,
      },
    });
    queryClient.refetchQueries({ queryKey: [uuid || ''] });
    setLoading(false);
  };
  const handleDeleteLine = (line: Line) => {
    const updatedLines = jsonQ.clone(lines);
    const index = lines?.findIndex((l: Line) =>
      line?.uuid ? l?.uuid === line?.uuid : l?.key === line?.key
    );
    updatedLines?.splice(index, 1);
    setOnUpdateLine(false);
    setLines(updatedLines);
    setOnCreate(false);
  };
  const totalProviderAmount = () => {
    let total = 0;
    lines?.forEach(
      l => (total = total + getFormulaDutyFormulaAmount(l, 'PROVIDER'))
    );
    return total;
  };
  const totalCustomerAmount = () => {
    let total = 0;
    lines?.forEach(
      l => (total = total + getFormulaDutyFormulaAmount(l, 'CUSTOMER'))
    );
    return total;
  };
  const { mutateAsync: createAdditionalActivity } =
    useCreateAditionalActivity();
  const { mutateAsync: updateAdditionalActivity } =
    useAdditionalActivityUpdateOne();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSubmit = async (saveLater: boolean) => {
    const formValues = getValues();
    setError('attachments', { message: undefined });
    //@ts-ignores
    const attachments = formValues?.attachments;
    if (!attachments?.length) {
      if (isAttachmentsRequired(avalaibleFormulas, lines)) {
        setError('attachments', {
          message: 'Au moins un justificatif est requis',
        });
        return;
      }
    }
    const submitValues = {
      saveAndFinishLater: saveLater,
      missionReference: mission?.reference as string,
      type: EadditionalActivityType?.STAND_BY_DUTY,
      providerComment,
      month: removeOffsetDate(month),
      attachments,
      standByDuties: lines?.map(l => {
        return {
          ...l,
          lock: undefined,
          key: undefined,
        };
      }) as IAdditionalActivityStandByDutyData[],
    };
    try {
      setLoading(true);
      if (method === 'CREATE') {
        await createAdditionalActivity(submitValues);
      }
      if (method === 'UPDATE') {
        const removeStandByDutyLines: string[] | [] = [];
        standByDutyLines?.forEach(sd => {
          if (!lines?.find(l => l?.uuid === sd?.uuid)) {
            removeStandByDutyLines?.push(sd.uuid as never);
          }
        });

        const addAttachments: any[] = [];
        const removeAttachments: any[] = [];
        const existingAttachmentsFileNames =
          existingAttachments?.map((f: any) => f.fileName) || [];

        const currentAttachmentsFileNames =
          attachments?.map((file: any) => file.fileName) || [];

        attachments?.forEach((sd: any) => {
          if (!existingAttachmentsFileNames?.includes(sd?.fileName)) {
            addAttachments?.push(sd);
          }
        });
        existingAttachments?.forEach((sd: any) => {
          if (!currentAttachmentsFileNames?.includes(sd?.fileName)) {
            removeAttachments?.push(sd);
          }
        });

        await updateAdditionalActivity({
          uuid: uuid,
          ...submitValues,
          removeStandByDutyLines,
          addAttachments,
          removeAttachments,
          status: EadditionalActivityStatus.TO_BE_SUBMITTED,
        });
      }
      queryClient.refetchQueries({ queryKey: ['additionalActivities'] });
      queryClient.refetchQueries({ queryKey: ['ActivitiesReport'] });

      showMessage(
        'success',
        `Votre prestation complémentaire à été ${
          method === 'UPDATE' ? 'mise à jour' : 'créée'
        }`
      );
      if (onResolve && typeof onResolve === 'function') {
        onResolve(true);
      }
    } catch (e) {
      setLoading(false);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (activity) {
      setProviderComment(activity?.providerComment);
    }
  }, [activity]);
  useEffect(() => {
    if (method === 'UPDATE' && uuid) {
      if (activity && activity?.standByDutyLines?.length) {
        setLines(
          activity?.standByDutyLines?.map((sd: any) => ({
            ...sd,
            lock: true,
          }))
        );
      }
    }
  }, [activity, method, uuid]);
  if (isFetchingAddtional || isFetchingMission) {
    return <SpinnerBox />;
  }
  if (missionStandByDutyFormulas?.length === 0) {
    return (
      <Box width={1 / 1}>
        <Header tabs={false} height={120}>
          <Text variant="h1">Déclarer une activité</Text>
        </Header>
        Vous ne pouvez pas créer de prestation complémentaire sur cette mission.
      </Box>
    );
  }
  //TODO ON MANAGER DO NOT UPDATE !
  const isEditable = false;
  // !activity?.status ||
  // activity?.status === EadditionalActivityStatus?.TO_BE_FILLED ||
  // activity?.status === EadditionalActivityStatus?.TO_BE_SUBMITTED ||
  // activity?.status === EadditionalActivityStatus?.REJECTED;
  const getStatusData = (
    status: EadditionalActivityStatus
  ): {
    variantColor: 'grey' | 'warning' | 'success' | 'error';
    statusText: string;
    textColor: string;
  } => {
    switch (status) {
      case EadditionalActivityStatus.TO_BE_FILLED:
        return {
          variantColor: 'grey',
          statusText: 'A saisir',
          textColor: Theme.colors.grey.default,
        };
      case EadditionalActivityStatus.TO_BE_SUBMITTED:
        return {
          variantColor: 'grey',
          statusText: 'A soumettre',
          textColor: Theme.colors.grey.default,
        };
      case EadditionalActivityStatus.TO_BE_VALIDATED:
        return {
          variantColor: 'warning',
          statusText: 'En attente de validation',
          textColor: Theme.colors.warning.default,
        };
      case EadditionalActivityStatus.VALIDATED:
        return {
          variantColor: 'success',
          statusText: `Validé`,
          textColor: Theme.colors.success.default,
        };
      case EadditionalActivityStatus.REJECTED:
        return {
          variantColor: 'error',
          statusText: `Refusé`,
          textColor: Theme.colors.error.default,
        };
      case EadditionalActivityStatus.ARCHIVED:
        return {
          variantColor: 'grey',
          statusText: `Archivé`,
          textColor: Theme.colors.grey.default,
        };
      default:
        return {
          variantColor: 'grey',
          statusText: 'A saisir',
          textColor: Theme.colors.grey.default,
        };
    }
  };
  const statusData = getStatusData(
    activity?.status as EadditionalActivityStatus
  );
  const validateAdditionalActivity = async (additionalActivity: any) => {
    try {
      setIsLoading(true);
      const textType = getAdditionActivityTextType(additionalActivity);
      await updateAdditionalActivity({
        uuid: additionalActivity.uuid,
        status: EadditionalActivityStatus.VALIDATED,
        milestoneAmount: additionalActivity?.milestoneAmount,
        addAttachments: additionalActivity?.addAttachments,
        removeAttachments: additionalActivity?.removeAttachments,
      });
      showMessage(
        'success',
        `${transformText(textType, 'capitalize-first')} a bien été validé`
      );
      queryClient?.invalidateQueries({ queryKey: [additionalActivity.uuid] });
      queryClient.refetchQueries({
        queryKey: ['additionalActivities'],
        type: 'active',
      });
      queryClient?.refetchQueries({
        queryKey: ['ActivitiesReport'],
        type: 'active',
      });
    } catch (e) {
      //
      setIsLoading(false);
    }
    setIsLoading(false);
  };
  return (
    <Box width={1 / 1}>
      <Flex alignItems="center" alignContent="center">
        <Text variant="h1" mb={20}>
          {method === 'UPDATE' ? 'Modifier' : 'Déclarer'} une prestation
          complémentaire
        </Text>
        {method === 'UPDATE' && (
          <ColorTextBox variantColor={statusData?.variantColor} ml={5}>
            {statusData?.statusText}
          </ColorTextBox>
        )}
      </Flex>
      <Flex mb={10}>
        <Tab
          onClick={() => setFormSelected('INFORMATIONS')}
          isSelected={formSelected === 'INFORMATIONS'}
          variant="secondary"
          fontSize={12}
        >
          Informations
        </Tab>
        <Tab
          onClick={() => setFormSelected('HISTORY')}
          isSelected={formSelected === 'HISTORY'}
          variant="secondary"
          fontSize={12}
        >
          Historique
        </Tab>
      </Flex>
      {activity?.rejectReason &&
        activity?.status === EadditionalActivityStatus?.REJECTED && (
          <div style={{ padding: 5 }}>
            <TextInformation variant="error" width={1 / 1} mb={10}>
              {`Cette prestation complémentaire été refusé le ${new Date(
                activity?.statusChangedAt as Date
              ).toLocaleDateString()} pour cause de : ${activity?.rejectReason}`}
            </TextInformation>
          </div>
        )}
      {formSelected === 'HISTORY' && (
        <Box>
          <HistoryList
            columns={columns}
            filterObject={{
              ownerId: uuid,
              ownerType: EChangeOwnerType?.ACTIVITY,
            }}
          />
        </Box>
      )}
      {formSelected === 'INFORMATIONS' && (
        <Box>
          <Box width={12 / 12}>
            <Box width={12 / 12}>
              <Flex
                height={'100px'}
                p={25}
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                backgroundColor="#edf3ff"
                borderRadius="8px"
              >
                <LabelField
                  underline
                  label="Période"
                  value={getHumanDateMonthAndYear(month)}
                />
                <LabelField
                  underline
                  ml={5}
                  label="Référence mission"
                  value={mission?.displayReference}
                />
                <LabelField
                  underline
                  ml={5}
                  label="Client"
                  value={
                    mission &&
                    getTradeNameSafe(mission?.customer?.establishment)
                  }
                />
                <LabelField
                  underline
                  ml={5}
                  label="Intervenant"
                  value={getFullName(mission?.provider?.contractor)}
                />
              </Flex>
            </Box>
          </Box>
          {lines
            ?.sort((a: Line, b: Line) => Number(b.lock) - Number(a.lock))
            ?.map((l: Line, index) => (
              <AddDutyLine
                key={`line_${index}`}
                isEditable={isEditable}
                readOnly={onUpdateLine === l?.uuid ? false : l?.lock}
                line={l}
                mission={mission as IJoinedMission}
                onCreateOrUpdateLine={(line: Line) =>
                  handleCreateOrUpdateLine(line)
                }
                unLockToUpdate={(line: Line) =>
                  setOnUpdateLine(line?.uuid as string)
                }
                onDeleteLine={(line: Line) => handleDeleteLine(line)}
                avalaibleFormulas={avalaibleFormulas}
              />
            ))}
          {onCreate && (
            <AddDutyLine
              key={`line_add`}
              readOnly={false}
              //@ts-ignore
              line={defaultLine}
              mission={mission as IJoinedMission}
              onCreateOrUpdateLine={(line: Line) =>
                handleCreateOrUpdateLine(line)
              }
              onDeleteLine={(line: Line) => handleDeleteLine(line)}
              avalaibleFormulas={avalaibleFormulas}
            />
          )}
          {!onCreate && isEditable && (
            <Box mt={20}>
              <Link
                onClick={() => setOnCreate(true)}
                iconLeft={<AddIcon fill={Theme?.colors?.primary?.default} />}
              >
                Ajouter une prestation complémentaire
              </Link>
            </Box>
          )}
          <Box mt={25}>
            <Text variant="b" textAlign={'left'}>
              Justificatifs
            </Text>

            <FormControl
              label=""
              errorMessage={errors?.attachments?.message}
              mb={-20}
            />
            <FileListControlled
              control={control}
              maxFile={lines.length + ADDIONAL_FILES_NUMBER}
              name="attachments"
              missionRef={mission?.reference}
              isDisabled={!isEditable}
              readOnly={!isEditable}
              actionName={'Importer un justificatif'}
              accept=".pdf"
              defaultValue={activity?.attachments as any}
              onChangeAction={(e: any) => {
                if (e?.length) setError('attachments', { message: undefined });
              }}
            />
          </Box>
          <Box width={12 / 12} mt={20}>
            <Box width={12 / 12}>
              <FormControl label="Commentaire envoyé">
                <TextArea
                  isDisabled={!isEditable}
                  minRows={3}
                  name={'providerComment'}
                  value={providerComment}
                  onChange={e => setProviderComment(e?.target?.value)}
                  placeholder={
                    isEditable
                      ? 'Ajouter un commentaire destiné aux équipes Freelance.com'
                      : 'N/A'
                  }
                />
              </FormControl>
            </Box>
          </Box>
          <Box width={12 / 12}>
            <FormControl label="Devis client">
              <ActivityQuoteCard
                additionalActivity={activity}
                isDisabled={
                  activity?.status === EadditionalActivityStatus.VALIDATED
                }
              />
            </FormControl>
            <FormControl label="BDC client">
              {activity?.purchaseOrder?.file ? (
                <FileCard
                  layer={105}
                  isDisabled={
                    activity?.status === EadditionalActivityStatus.VALIDATED
                  }
                  fileName={
                    `${
                      activity?.purchaseOrder?.reference || 'BDC'
                    }_${activity?.mission.displayReference.replace(
                      / /g,
                      '_'
                    )}.pdf` || ''
                  }
                  fileLocation={
                    activity?.purchaseOrder?.file?.fileLocation ?? ''
                  }
                  complement={
                    <Status variantColor="success">{`${
                      activity?.purchaseOrder?.status
                        ? FR_STATUS[
                            activity.purchaseOrder
                              ?.status as keyof typeof FR_STATUS
                          ]
                        : ''
                    } le ${formatDate(
                      activity?.purchaseOrder?.updatedAt ||
                        activity?.purchaseOrder?.createdAt ||
                        ''
                    )}`}</Status>
                  }
                  actions={
                    <>
                      <MenuItem
                        onClick={() =>
                          showImportBDCAdditionalActivityModal({
                            additionalActivity:
                              activity as IJoinedAdditionalActivity,
                          })
                        }
                      >
                        <Link iconLeft={<EditIcon />}>
                          Modifier le BDC client
                        </Link>
                      </MenuItem>
                      <MenuItem onClick={onDeletePo}>
                        <Link iconLeft={<DeleteIcon />}>
                          Supprimer le BDC client
                        </Link>
                      </MenuItem>
                    </>
                  }
                />
              ) : (
                <>
                  {activity?.status !== EadditionalActivityStatus.VALIDATED && (
                    <AddBloc>
                      <Flex>
                        <Link
                          iconLeft={<AddIcon />}
                          onClick={() =>
                            showImportBDCAdditionalActivityModal({
                              additionalActivity:
                                activity as IJoinedAdditionalActivity,
                            })
                          }
                        >
                          Importer un BDC client
                        </Link>
                      </Flex>
                    </AddBloc>
                  )}
                </>
              )}
            </FormControl>
          </Box>
          <Flex width={1 / 1} justifyContent="flex-end" mt={10}>
            <Box width={1 / 1}>
              <Text
                p={10}
                variant="h1"
                color="#009933"
                display="flex"
                alignItems="center"
                justifyContent="center"
                backgroundColor="rgba(0, 153, 51, 0.1)"
                fontWeight={500}
              >
                Montant fournisseur total HT : {round(totalProviderAmount())}
                <EuroIcon fill="#009933" />
              </Text>
            </Box>
            <Box width={1 / 1}>
              <Text
                p={10}
                variant="h1"
                color="#009933"
                display="flex"
                alignItems="center"
                justifyContent="center"
                backgroundColor="rgba(0, 153, 51, 0.1)"
                fontWeight={500}
              >
                Montant client total HT : {round(totalCustomerAmount())}
                <EuroIcon fill="#009933" />
              </Text>
            </Box>
          </Flex>
          <Flex mt={10} justifyContent="flex-end">
            {activity?.status ===
              EadditionalActivityStatus?.TO_BE_VALIDATED && (
              <Flex>
                <Button
                  isLoading={loading || isLoading}
                  isDisabled={lines?.length < 1}
                  variant="success"
                  ml={10}
                  onClick={async () => {
                    if (activity?.mission?.isMandateMode) {
                      await validateAdditionalActivity(activity);
                    } else {
                      showValidateAdditionalActivityModal({
                        additionalActivity: activity,
                      })?.then(action => {
                        if (action) {
                          if (onResolve) {
                            onResolve(true);
                          }
                        }
                      });
                    }
                  }}
                >
                  Valider
                </Button>
                <Button
                  isLoading={loading}
                  isDisabled={lines?.length < 1}
                  variant="ghostError"
                  ml={10}
                  onClick={async () => {
                    await showRejectAdditionalActivityModal({
                      additionalActivity: activity,
                    })?.then(action => {
                      if (action) {
                        queryClient?.invalidateQueries({
                          queryKey: ['additionalActivities'],
                          type: 'active',
                        });
                        queryClient?.refetchQueries({
                          queryKey: ['ActivitiesReport'],
                          type: 'active',
                        });

                        if (onResolve) {
                          onResolve(true);
                        }
                      }
                    });
                  }}
                >
                  Refuser
                </Button>
              </Flex>
            )}
          </Flex>
        </Box>
      )}
    </Box>
  );
};

CreateStandByDutyForm.displayName = 'CreateStandByDutyForm';

export default CreateStandByDutyForm;
