import {
  useInvoiceFindMany,
  INVOICE_STATUS_SELECT,
  INVOICE_CUSTOMER_TYPE_SELECT,
  INVOICE_CUSTOMER_TYPES,
  invoiceSendReminder,
  isMileStoneInvoice,
  formatInvoiceOrAdditionalActivitySort,
} from '@commons';
import {
  EInvoiceStatus,
  IJoinedInvoice,
  IJoinedMission,
  EInvoiceDocumentType,
  EInvoiceType,
  EBillingType,
} from '@freelancelabs/teoreme-commons';
import { StaticSelect } from 'components/selects/StaticSelect';
import { showDisplayPdfModal } from 'components/modals/DisplayPdfModal';
import { ALink } from 'components/ALink';
import { showGenerateInvoiceModal } from 'components/modals/invoices/GenerateInvoiceModal';
import {
  Box,
  Container,
  DatePickerRange,
  Flex,
  FormLabel,
  Input,
  Itemize,
  SpinnerBox,
  Status,
  Table,
  TableColumn,
  Text,
  Link,
  Menu,
  MenuItem,
  Spinner,
} from 'components/ui';
import {
  DotsIcon,
  EyeIcon,
  RefreshIcon,
  ValidateIcon,
  SendIcon,
} from 'components/ui/icons';
import { useMutation } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { add, getTime } from 'date-fns';
import { useDebounce } from 'hooks/useDebounce';
import { useShowMessage } from 'hooks/useShowMessage';
import { useTranslationFormat } from 'hooks/useTranslateFormat';
import { kebabCase } from 'lodash';
import * as React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Theme } from 'styles';
type Item = Itemize<IJoinedInvoice>;
const INVOICE_STATUS_SELECT_CUSTOM = INVOICE_STATUS_SELECT;
const Findex = INVOICE_STATUS_SELECT.findIndex(
  state => state.key === EInvoiceStatus.REJECTED
);
INVOICE_STATUS_SELECT_CUSTOM[Findex].label = 'Refusé';
const LIMIT = 5;

const columns = (
  statusSelect: string,
  typeSelect: string,
  ReSendButton: any,
  invoiceTypeText: any,
  history: any,
  showMessage: any
) => {
  const status = kebabCase(statusSelect).toLocaleLowerCase();

  return [
    {
      key: 'month',
      sortKey: 'month',
      text: 'MOIS',
      render: (item: IJoinedInvoice) => {
        if (
          isMileStoneInvoice(item?.invoiceType) ||
          item?.mission?.billing?.type === EBillingType.FLAT_RATE
        ) {
          return `${
            new Date(item?.createdAt as Date)?.getUTCMonth() + 1
          }/${new Date(item?.createdAt as Date)?.getUTCFullYear()}`;
        }

        return `${new Date(item?.month as Date)?.getUTCMonth() + 1}/${new Date(
          item?.month as Date
        )?.getUTCFullYear()}`;
      },
    },
    {
      key: 'type',
      sortKey: 'type',
      text: 'Type',
      render: (item: IJoinedInvoice) => {
        const isMarginInvoice = item?.isMarginInvoice ? ' (marge)' : '';
        return invoiceTypeText(item.invoiceType) + isMarginInvoice;
      },
    },
    {
      key: 'totalBeforeTaxes',
      sortKey: 'totalBeforeTaxes',
      text: `Montant HT`,
      //hidden: status === 'awaiting',
      render: (item: IJoinedInvoice) =>
        `${item?.totalBeforeTaxes?.toFixed(2) || 'N/A'} €`,
    },
    {
      key: 'status',
      sortKey: 'status',
      text: 'Statut',
      hidden: status === 'to-be-validated',
      render: (item: IJoinedInvoice) => {
        const status = item.status;
        const statusChangedAt = item?.statusChangedAt;
        let variantColor = 'grey';
        let text = '';
        switch (status) {
          case EInvoiceStatus.PROJECTED:
            variantColor = 'grey';
            text = 'Prévisionnelle';
            break;
          case EInvoiceStatus.TO_BE_FILLED:
            variantColor = 'grey';
            text = 'A saisir';
            break;
          case EInvoiceStatus.TO_BE_SUBMITTED:
            variantColor = 'grey';
            text = 'Non soumise';
            break;
          case EInvoiceStatus.TO_BE_VALIDATED:
            variantColor = 'warning';
            text = `Transmise le : ${
              statusChangedAt && statusChangedAt.toLocaleDateString()
            }`;
            break;
          case EInvoiceStatus.VALIDATED:
            variantColor = 'success';
            text = `Validé  ${
              statusChangedAt && statusChangedAt.toLocaleDateString()
            }`;
            break;
          case EInvoiceStatus.ARCHIVED:
            variantColor = 'grey';
            text = 'Archivé';
            break;
          case EInvoiceStatus.REJECTED:
            variantColor = 'error';
            text = `Refusé le ${
              statusChangedAt && statusChangedAt.toLocaleDateString()
            }`;
            break;
          case EInvoiceStatus.CANCELLED:
            variantColor = 'error';
            text = `Annulé le ${
              statusChangedAt && statusChangedAt.toLocaleDateString()
            }`;
            break;
          case EInvoiceStatus.SENT:
            variantColor = 'success';
            text = `Envoyé le ${
              statusChangedAt && statusChangedAt.toLocaleDateString()
            }`;
            break;
          case EInvoiceStatus.PAID:
            variantColor = 'success';
            text = `Payée ${
              item?.sage?.paymentDetails?.paymentDate
                ? '- Virement Recu le : ' +
                  item?.sage?.paymentDetails?.paymentDate?.toLocaleDateString()
                : ''
            }`;
            break;
          default:
            variantColor = 'grey';
        }

        return (
          <Flex>
            <Status
              //@ts-ignore
              variantColor={variantColor}
            >
              {text}
            </Status>
          </Flex>
        );
      },
    },
    {
      key: 'deliveryOrder',
      //sortKey: 'invoice',
      text: 'STATUS GESCOM',
      render: (item: IJoinedInvoice) => {
        const customerReference =
          item?.mission?.customer?.purchaseOrder?.reference;
        if (!customerReference) {
          return (
            <>
              <a data-for={item.uuid} data-tip="" data-iscapture="true">
                <ReactTooltip
                  id={item.uuid}
                  place={'top'}
                  variant={'dark'}
                  float={false}
                  style={{ backgroundColor: Theme.colors.error.default }}
                >
                  <Text color="white" fontSize={11}>
                    Le bon de commande client est requis pour créer les bons de
                    livraison dans gescom.
                  </Text>
                </ReactTooltip>
                <ALink
                  href={`/delivery/missions/${item?.mission?.reference}/client`}
                >
                  <Status variantColor="error">BDC Client manquant</Status>
                </ALink>
              </a>
            </>
          );
        }
        if (!item?.sage) {
          return <Status variantColor="grey">À créer dans GESCOM</Status>;
        }
        if (!item?.sage?.documentType) {
          return <Status variantColor="grey">À créer dans GESCOM</Status>;
        }
        if (item?.sage?.documentType === EInvoiceDocumentType.DELIVERY_ORDER) {
          return <Status variantColor="success">BDL Créé dans GESCOM</Status>;
        }
        if (item?.sage?.documentType === EInvoiceDocumentType.INVOICE) {
          return (
            <Status variantColor="success">Facture Créé dans GESCOM</Status>
          );
        }
      },
    },
    {
      key: 'sageInvoice',
      text: 'FACTURE N°',
      render: (item: IJoinedInvoice) => {
        if (
          item?.sage?.invoiceId &&
          item?.sage?.documentType === EInvoiceDocumentType.INVOICE
        ) {
          return (
            <Status variantColor="success">{item?.sage?.invoiceId}</Status>
          );
        }
        return <> N/A</>;
      },
    },
    {
      key: 'actions',
      text: 'ACTIONS',
      align: 'flex-end',
      render: (
        item: IJoinedInvoice & {
          providerInvoice?: IJoinedInvoice;
        }
      ) => {
        const icon = <EyeIcon style={{ marginTop: 4 }} />;
        const invoiceId = item?.uuid;
        const invoiceStatus = item?.status;
        const missionRef = item?.mission?.reference;
        const contractorId = item?.contractor?.uuid;
        const invoiceFile = item?.invoiceFile;
        return (
          <Flex alignItems="center">
            {(invoiceStatus === EInvoiceStatus.TO_BE_FILLED ||
              invoiceStatus === EInvoiceStatus.TO_BE_SUBMITTED ||
              invoiceStatus === EInvoiceStatus.REJECTED) && (
              <Flex mr={'5px'} mt={'10px'}>
                <ReSendButton invoice={item} />
              </Flex>
            )}
            {invoiceStatus === EInvoiceStatus.TO_BE_VALIDATED && (
              <Flex mr={'5px'} mt={'10px'} mb={'10px'}>
                {item?.sage && (
                  <>
                    <ValidateIcon fill={'red !important'} />
                    <ALink
                      href={`/orders/invoices/provider/time-spent/${invoiceId}`}
                    >
                      <Link>Valider</Link>
                    </ALink>
                  </>
                )}
              </Flex>
            )}
            <ALink href={`/orders/invoices/customer/time-spent/${invoiceId}`}>
              <Link iconLeft={icon}></Link>
            </ALink>
            <Menu
              align="right"
              menuItems={
                <>
                  {invoiceFile && (
                    <MenuItem>
                      <Link
                        onClick={() =>
                          showDisplayPdfModal({
                            fileLocation: invoiceFile.fileLocation,
                            //@ts-ignore
                            fileName: invoiceFile.fileName,
                          })
                        }
                        iconLeft={icon}
                      >
                        Voir la facture
                      </Link>
                    </MenuItem>
                  )}
                  <MenuItem>
                    <ALink href={`/delivery/missions/${missionRef}`}>
                      <Link iconLeft={icon}>Voir la mission</Link>
                    </ALink>
                  </MenuItem>
                  <MenuItem>
                    <ALink href={`/providers/contractors/${contractorId}`}>
                      <Link iconLeft={icon}>Voir la fiche intervenant</Link>
                    </ALink>
                  </MenuItem>
                  {/*
                    - Check CRA status
                    - Only CRAs with existing BL can generate the customer invoice
                    - Check  customer invoice  status
                    - TODO CHECK IF VALIDATED BY SAGE !
                  */}
                  {!item?.sage &&
                    item?.status === EInvoiceStatus?.PROJECTED && (
                      <MenuItem>
                        <Link
                          onClick={() =>
                            showGenerateInvoiceModal({
                              invoiceUuid: item?.uuid,
                            })
                          }
                          iconLeft={<SendIcon />}
                        >
                          Générer et envoyer la facture client
                        </Link>
                      </MenuItem>
                    )}
                  {/* {!item?.sage && customerReference && (
                    <MenuItem>
                      <Link
                        onClick={() =>
                          showCreateGescomBDLModal({
                            invoice: item,
                            cra: item?.cra,
                            mission: item?.mission,
                            delivreyOrders: {
                              bdlProvider: true,
                              bdlCustomer: false,
                            },
                            customerReference:
                              item?.mission?.customer?.purchaseOrder
                                ?.reference || '',
                            refetchQueryKey: 'Invoices',
                          })
                        }
                        iconLeft={<EditIcon />}
                      >
                        Créer dans GESCOM
                      </Link>
                    </MenuItem>
                  )} */}
                </>
              }
            >
              <DotsIcon fill={Theme?.colors?.primary?.default} fontSize={20} />
            </Menu>
          </Flex>
        );
      },
    },
  ].filter(Boolean) as TableColumn<Item>[];
};

type InvoiceMissionProps = {
  mission: IJoinedMission;
};
export const CustomerInvoicesMission = ({ mission }: InvoiceMissionProps) => {
  const history = useHistory();
  const [order, setOrder] = React.useState<'desc' | 'asc' | undefined>('desc');
  const [sortedBy, setSortedBy] = React.useState('month');
  const sortString = (order === 'desc' ? '-' : '') + sortedBy;
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>();
  const debouncedFilterQuery = useDebounce(searchQuery, 500);
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const [statusSelect, setStatusSelect] = React.useState<string>('ALL');
  const [typeSelect, setTypeSelect] = React.useState<string>('ALL');
  const [page, setPage] = React.useState(0);

  const {
    data: InvoiceQuery,
    status,
    isFetching,
  } = useInvoiceFindMany({
    filterObject: {
      invoiceType:
        typeSelect && typeSelect !== 'ALL'
          ? typeSelect
          : { $in: INVOICE_CUSTOMER_TYPES },
      status: statusSelect !== 'ALL' ? statusSelect : undefined,
      mission: mission?.reference,
      $and:
        startDate && endDate
          ? [
              {
                $expr: {
                  $gte: [{ $toLong: '$month' }, getTime(startDate)],
                },
              },
              {
                $expr: {
                  $lte: [
                    { $toLong: '$month' },
                    getTime(add(endDate, { months: 1 })),
                  ],
                },
              },
            ]
          : undefined,
      $or: debouncedFilterQuery
        ? [
            // customer
            {
              'estCustomer.businessName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.tradeName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.signBoard1': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.signBoard2': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.signBoard3': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.identifier': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.siret': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.siren': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            // provider
            {
              'estProvider.businessName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.tradeName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.signBoard1': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.signBoard2': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.signBoard3': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.identifier': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.siret': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.siren': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'sage.invoiceId': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
          ]
        : undefined,
    },
    limit: LIMIT,
    skip: LIMIT * page,
    sort: formatInvoiceOrAdditionalActivitySort(sortString),
  });

  const loading = status === 'pending';
  const totalCount = InvoiceQuery?.totalCount || 0;

  const items = InvoiceQuery?.invoices?.map(invoice => ({
    key: invoice?.uuid,
    ...invoice,
  }));

  const onSortedChange = React.useCallback(
    (shortBy: string, order: 'asc' | 'desc'): void => {
      if (mission?.billing?.type === EBillingType.FLAT_RATE) {
        if (shortBy === 'month') {
          shortBy = 'createdAt';
        }
      }
      setSortedBy(shortBy);
      setOrder(order);
    },
    []
  );

  const showMessage = useShowMessage();
  const t = useTranslationFormat();

  const invoiceTypeText = (invoiceType: EInvoiceType) => {
    return t(`texts:INVOICETYPE_${invoiceType}`, 'capitalize-first');
  };
  const ReSendButton = ({ invoice }: { invoice: IJoinedInvoice }) => {
    const { mutateAsync: onResend, status } = useMutation({
      mutationFn: () =>
        invoiceSendReminder({
          uuid: invoice.uuid,
        }),
    });
    if (status === 'pending') return <Spinner size={20} />;
    if (status === 'success') {
      showMessage('success', 'Votre relance a bien été envoyée');
    }
    if (status === 'error') {
      showMessage(
        'error',
        'Suite à une erreur technique, votre relance n’a pas pu être envoyée. Veuillez réessayer'
      );
    }

    return (
      <Link
        style={{ paddingBottom: 15 }}
        iconLeft={<RefreshIcon />}
        onClick={() => onResend()}
      >
        Relancer
      </Link>
    );
  };

  React.useEffect(() => {
    setPage(0);
  }, [statusSelect, typeSelect]);

  return (
    <>
      <Container p="20px 0">
        <Flex justifyContent="space-between" ml={20} mr={20} mb={10}>
          <Box width={4 / 12} mr={10}>
            <FormLabel>RECHERCHER</FormLabel>
            <Input
              isFullWidth
              type="search"
              value={searchQuery}
              onChange={e => {
                setPage(0);
                //@ts-ignore
                setSearchQuery(e.target.value);
              }}
            />
          </Box>

          <Box width={4 / 12} mr={10}>
            <FormLabel pl={20}>PÉRIODE</FormLabel>
            <DatePickerRange
              startDate={startDate}
              endDate={endDate}
              isClearable={true}
              setStartDate={value => {
                setStartDate(value as Date);
                setPage(0);
              }}
              setEndDate={value => {
                setEndDate(value as Date);
                setPage(0);
              }}
            />
          </Box>

          <Box width={4 / 12} mr={10}>
            <FormLabel>Statuts</FormLabel>
            <StaticSelect
              placeholder="Tous les statuts"
              options={INVOICE_STATUS_SELECT_CUSTOM.filter(
                data =>
                  data.key === 'ALL' ||
                  data.key === EInvoiceStatus.CANCELLED ||
                  data.key === EInvoiceStatus.PROJECTED ||
                  data.key === EInvoiceStatus.REJECTED ||
                  data.key === EInvoiceStatus.VALIDATED ||
                  data.key === EInvoiceStatus.SENT ||
                  data.key === EInvoiceStatus.PAID
              )}
              onChange={value => setStatusSelect(value as string)}
              value={statusSelect}
            />
          </Box>
          <Box width={4 / 12} mr={10}>
            <FormLabel>Type</FormLabel>
            <StaticSelect
              placeholder="Tous les types"
              options={INVOICE_CUSTOMER_TYPE_SELECT}
              onChange={value => setTypeSelect(value as string)}
              value={typeSelect}
            />
          </Box>
        </Flex>
        {loading && <SpinnerBox />}
        {!loading && items && items?.length > 0 ? (
          <Table<Itemize<IJoinedInvoice>>
            isLoading={isFetching}
            columns={columns(
              statusSelect,
              typeSelect,
              ReSendButton,
              invoiceTypeText,
              history,
              showMessage
            )}
            items={items}
            sortedBy={sortedBy}
            order={order}
            page={page}
            total={totalCount}
            perPage={totalCount < LIMIT ? totalCount : LIMIT}
            onSortedChange={onSortedChange}
            onChangePage={page => setPage(page)}
          />
        ) : (
          <Flex
            height={'50vh'}
            justifyContent="center"
            alignContent="center"
            alignItems="center"
          >
            <Box>
              <Text>Aucune facture client pour cette mission</Text>
            </Box>
          </Flex>
        )}
      </Container>
    </>
  );
};
