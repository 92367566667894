import { useAlertStore } from 'store/alert';
// Hook
export function useShowMessage(): (
  variant: 'error' | 'success' | 'info',
  message: string
) => void {
  const { updateAlert } = useAlertStore();
  return (variant: 'error' | 'success' | 'info', message: string) => {
    updateAlert({
      open: true,
      variant,
      message,
    });
  };
}
